// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import { useStyles } from './Styles';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { getAll } from "../../../../../Services/GenericApiService";
import { formatData } from "../../../../../Services/DataFormatService";
import { useTranslation } from "react-i18next";
import AlertM from '../../../../Helpers/AlertM/AlertM';
import { log } from "../../../../../Services/LoggerService";
import dayjs from 'dayjs';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import { Button, IconButton, Skeleton, FormControl, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function PaymentlogsMain() {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = 'paymentimportlog';

    // data vars
    const [allData, setAllData] = React.useState([]);
    const [dataToEdit, setDataToEdit] = React.useState({});
    const [fileName, setFileName] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);

    let columns = [
        { name: "File Name", options: { filterOptions: { fullWidth: true } } },
        { name: "Status", options: { filterOptions: { fullWidth: true } } },
        { name: "Imported Date", options: { filterOptions: { fullWidth: true } } },
    ];
    const [tableData, setTableData] = React.useState([]);

    // ui controls
    const [loading, setLoading] = React.useState(true);
    const [editMode, setEditMode] = React.useState(false);

    // table ui controls
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [searchBtn, setSearchBtn] = useState(true);
    const [downloadBtn, setDownloadBtn] = useState(true);
    const [printBtn, setPrintBtn] = useState(true);
    const [viewColumnBtn, setViewColumnBtn] = useState(true);
    const [filterBtn, setFilterBtn] = useState(true);


    // fetch dependent data
    const fetch = (_fileName, _importedDate) => {
        setLoading(true); // show loader
        getAll(`${moduleMain}/${_fileName}/${_importedDate}`)
            .then((_res: any) => {
                setAllData(_res)

                const rows: any = [];
                _res.forEach((_record: any) => {
                    rows.push(formatData([_record?.fileName, _record?.status, _record?.importDate]))
                });

                setTableData(rows);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    useEffect(() => {
        fetch(null, null)
    }, [])

    let options = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: "textField",
        responsive: "simple",
        selectableRowsHideCheckboxes: false,
        sort: false,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        }
    };

    if (tableData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ?
                        <Box>
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                        </Box> :
                        'Sorry, there is no matching data to display',
                },
            },
        };
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* Header */}
            <Header />

            {/* Sider */}
            <Sider />

            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
                <div className="px-1 px-md-5">

                    {/* Upper Section */}
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="mt-3">
                                {t('module.paymentlogs')}
                            </h2>
                        </div>
                    </div>

                    <div className={"container-fluid card shadow-lg py-4 px-3 mt-4"}>
                        <h4 className={'px-2 mb-5'}>
                            Filters
                        </h4>

                        <div className="row">
                            {/* First column: Input field */}
                            <div className="col-md-4">
                                <TextField
                                    fullWidth
                                    label="File Name"
                                    variant="outlined"
                                    value={fileName}
                                    onChange={(e) => setFileName(e.target.value)}
                                />
                            </div>

                            {/* Second column: Date input field */}
                            <div className="col-md-4">
                                <form>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label={t('campaign.dateStart')}
                                                value={selectedDate}
                                                onChange={(newValue) => {
                                                    const formattedDate = dayjs(newValue).format('YYYY-MM-DD'); // Format as 'yyyy-mm-dd'
                                                    setSelectedDate(formattedDate);
                                                    console.log(newValue); // Correct placement
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </form>
                            </div>

                            {/* Third column: Apply Filter Button */}
                            <div className="col-md-4 pt-2">
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => fetch(fileName, selectedDate)}
                                >
                                    Apply Filter
                                </Button>
                            </div>
                        </div>

                    </div>


                    <div className="mt-5">
                        <Paper elevation={12}>
                            <MUIDataTable
                                title={t('common.allRecords')}
                                data={tableData}
                                columns={columns}
                                // @ts-ignore
                                options={options}
                            />
                        </Paper>
                    </div>

                </div>

                {/* Alerts */}
                <AlertM ref={notifications} />

            </Box>


        </Box>
    );
}

export default PaymentlogsMain;
