// @ts-nocheck

import React, {forwardRef, useRef, useImperativeHandle, useEffect} from 'react';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import axios from 'axios';
import useFileUpload from 'react-use-file-upload';
import {read, writeFileXLSX, utils} from "https://cdn.sheetjs.com/xlsx-0.18.7/package/xlsx.mjs";
import Button from '@mui/material/Button';
import {getAll, getById, post, patch, deleteById, postFormData} from "../../../Services/GenericApiService";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import BookfieldCreate from "../../Screens/Dashboard/Bookfield/BookfieldCreate/BookfieldCreate";
import {log} from "../../../Services/LoggerService";
import {FormControlLabel, Checkbox, FormHelperText} from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import AlertM from '../../Helpers/AlertM/AlertM';

var XLSX = require("xlsx");

//create your forceUpdate hook
function useForceUpdate() {
    const [value, setValue] = React.useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render

}

// @ts-ignore
const AccountFileUploadM = forwardRef((props, ref) => {
    const {
        files,
        fileNames,
        fileTypes,
        totalSize,
        totalSizeInBytes,
        handleDragDropEvent,
        clearAllFiles,
        createFormData,
        setFiles,
        removeFile,
    } = useFileUpload();

    const inputRef = useRef();

    // child ref for alert
    const notifications = useRef();

    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );

    // get hardcoded template variables from env
    const preTerminationTemplateId = process.env.REACT_APP_PRE_TERMINATION_FILE_TEMPLATE_NAME;
    const collectionTemplateId = process.env.REACT_APP_COLLECTION_FILE_TEMPLATE_NAME;
    const allowedFileTypes = ['csv'];

    // data vars
    const [rowCount, setRowCount] = React.useState(0);
    const [headerRow, setHeaderRow] = React.useState([]);
    const [allBookFieldsData, setAllBookFieldsData] = React.useState([]);
    const [mapper, setMapper] = React.useState({});
    const [accountFile, setAccountFile] = React.useState();
    const [importResponse, setImportResponse] = React.useState({});
    const [bookTemplate, setBookTemplate] = React.useState({});
    const [allBookTemplates, setAllBookTemplates] = React.useState({});
    const [pageTitle, setPageTitle] = React.useState('');
    const [allCampaigns, setAllCampaigns] = React.useState([]);
    const [selectedCampaign, setSelectedCampaign] = React.useState({});


    // ui vars
    const [isMapperUIVisible, setIsMapperUIVisible] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isUploading, setIsUploading] = React.useState(false);
    const [isProcessing, setIsProcessing] = React.useState(false);
    const [isImported, setIsImported] = React.useState(false);


    // module(s) for api
    const moduleBookField = 'bookfield';
    const moduleBookUpload = 'debtordetail/upload/book';
    const moduleProcessBook = 'debtordetail';
    const moduleBookTemplate = 'booktemplate'
    const moduleCampaigns = 'campaign';

    const forceUpdate = useForceUpdate();

    // fetch dependent data
    const fetch = () => {
        fetchBookFields();
        fetchBookTemplates();
        fetchCampaigns();
    }

    const fetchBookFields = () => {
        getAll(moduleBookField)
            .then((_res: any) => {
                setAllBookFieldsData(_res);
            })
            .catch(_err => {
                log(_err);
            })
    }

    const fetchBookTemplates = () => {
        getAll(moduleBookTemplate)
            .then((_res: any) => {
                setAllBookTemplates(_res);

                // set book template by default
                const fileImportType = props.importType || 'pretermination';     // use pre termination import as default
                switch (fileImportType) {
                    case 'collection':
                        setBookTemplate(_res.filter(_template => _template.name == collectionTemplateId)[0]);
                        forceUpdate();
                        break;
                    case 'pretermination':
                        setBookTemplate(_res.filter(_template => _template.name == preTerminationTemplateId)[0]);
                        forceUpdate();
                        break;
                }
            })
            .catch(_err => {
                log(_err);
            })
    }

    const fetchCampaigns = () => {
        getAll(moduleCampaigns)
            .then((_res: any) => {
                setAllCampaigns(_res);
            })
            .catch(_err => {
                log(_err);
            })
    }

    useEffect(() => {
        fetch();

        // check upload type
        const fileImportType = props.importType || 'pretermination';     // use pre termination import as default

        switch (fileImportType) {
            case 'collection':
                setPageTitle('Collection Book Import');
                break;
            case 'pretermination':
                setPageTitle('Pre Termination Book Import');
                break;
        }

    }, []);


    const getNonEmptyFieldsFromMapper = () => {
        let processedObj = {};
        for (var key in mapper) {
            if (mapper.hasOwnProperty(key)) {
                if (mapper[key].value != "") {
                    processedObj[key] = {
                        value: mapper[key].value,
                        default: mapper[key].default
                    };
                }
            }
        }
        return processedObj;
    }


    const handleSubmit = async (e: any) => {
        e.preventDefault();

        // set loader
        setIsUploading(true);

        const formData = createFormData();

        var bodyFormData = new FormData();
        bodyFormData.append('file', accountFile, accountFile.name);

        postFormData(moduleBookUpload, bodyFormData)
            .then(_res => {
                // hide mapper ui
                setIsMapperUIVisible(false);

                // set loader
                setIsUploading(false);

                // set processing loader
                setIsProcessing(true);

                let payload = {
                    localPath: _res.localPath,
                    mapping: getNonEmptyFieldsFromMapper(),
                    isCollectionsBook: props.importType == 'collection',
                    campaignId: selectedCampaign.id
                }

                post(moduleProcessBook, payload)
                    .then(_resChain => {
                        // set processing loader
                        setIsProcessing(false);

                        // set is imported ui
                        setIsImported(true);

                        // store response results
                        setImportResponse(_resChain);

                        // @ts-ignore
                        notifications.current.successAlert('File Import Completed', 'Account were imported successfully');
                    })
                    .catch(_err => {
                        // set loader
                        setIsProcessing(false);

                        log(_err);
                        // @ts-ignore
                        notifications.current.errorAlert('Something went wrong','Please try again later');
                    })

            })
            .catch(_err => {
                // set loader
                setIsUploading(false);

                // set loader
                setIsProcessing(false);

                log(_err);
                // @ts-ignore
                notifications.current.errorAlert('Something went wrong','Please try again later');
            })
    };

    const onFileUpload = (_file, _event) => {
        // check if allowed file type
        if (allowedFileTypes.includes(_file.name.split('.')[1].toLowerCase())) {
            setAccountFile(_file);
            setFiles(_event, 'a');
            try {
                setMapper(JSON.parse(bookTemplate.mapping));
            } catch (e) {
                setMapper(bookTemplate.mapping);
            }

            // set ui for excel
            setIsMapperUIVisible(true);
            setIsLoading(false);
        } else {
            notifications.current.errorAlert('Upload Failed', 'Only csv file types are allowed');
            setIsLoading(false);
        }
    }


    return (
        <div>


            <div className={(theme == 'dark' ? 'bg-dark' : '') + " container card shadow-lg py-4 px-3"}>
                <div className="px-3 pb-3">
                    <h2 className={' mb-5'}>
                        {pageTitle}
                    </h2>

                    {fileNames.length > 0 &&
                        <div className={''}>
                            {/* File Info */}
                            <div className="alert alert-secondary">
                                <div className="row">
                                    {/* File Name */}
                                    <div className="col-12 col-md-12 mb-1">
                                        <p className={'text-muted p-0 m-0'}>
                                            File Name
                                        </p>
                                        <h5>
                                            {isLoading &&
                                                <Skeleton variant="text" sx={{fontSize: '1rem'}}/>
                                            }
                                            {!isLoading &&
                                                fileNames[0]
                                            }
                                        </h5>
                                    </div>

                                    {/*/!* No. of Records *!/*/}
                                    {/*<div className="col-6 col-md-4">*/}
                                    {/*    <p className={'text-muted p-0 m-0'}>*/}
                                    {/*        Number Of Records*/}
                                    {/*    </p>*/}
                                    {/*    <h5>*/}
                                    {/*        {isLoading &&*/}
                                    {/*            <Skeleton variant="text" sx={{fontSize: '1rem'}}/>*/}
                                    {/*        }*/}
                                    {/*        {!isLoading &&*/}
                                    {/*            rowCount - 1*/}
                                    {/*        }*/}
                                    {/*    </h5>*/}
                                    {/*</div>*/}
                                    {/*/!* No. of Columns *!/*/}
                                    {/*<div className="col-6 col-md-4">*/}
                                    {/*    <p className={'text-muted p-0 m-0'}>*/}
                                    {/*        Number Of Fields*/}
                                    {/*    </p>*/}
                                    {/*    <h5>*/}
                                    {/*        {isLoading &&*/}
                                    {/*            <Skeleton variant="text" sx={{fontSize: '1rem'}}/>*/}
                                    {/*        }*/}
                                    {/*        {!isLoading &&*/}
                                    {/*            headerRow.length*/}
                                    {/*        }*/}
                                    {/*    </h5>*/}
                                    {/*</div>*/}
                                    {/*/!* No. of System Fields *!/*/}
                                    {/*<div className="col-6 col-md-4">*/}
                                    {/*    <p className={'text-muted p-0 m-0'}>*/}
                                    {/*        Number Of System Fields*/}
                                    {/*    </p>*/}
                                    {/*    <h5>*/}
                                    {/*        {isLoading &&*/}
                                    {/*            <Skeleton variant="text" sx={{fontSize: '1rem'}}/>*/}
                                    {/*        }*/}
                                    {/*        {!isLoading &&*/}
                                    {/*            allBookFieldsData.length*/}
                                    {/*        }*/}
                                    {/*    </h5>*/}
                                    {/*</div>*/}

                                    {/* Action Buttons */}
                                    {!isImported &&
                                        <div className={'row'}>
                                            <div className="col-md-4 col-6 mt-3">
                                                {/*<BookfieldCreate refreshDataEvent={fetch} isParentNotMain={true}/>*/}
                                            </div>
                                            <div className="col-md-4 col-6 mt-3">
                                                <Button variant="outlined" className={'full-width my-2 py-3'}
                                                        onClick={() => clearAllFiles()}>
                                                    Clear
                                                </Button>
                                            </div>
                                            <div className="col-md-4 col-6 mt-3">
                                                <Button onClick={handleSubmit}
                                                        className={'full-width bg-custom-gradient my-2 py-3'}
                                                        variant="contained">
                                                    Import
                                                </Button>
                                            </div>
                                        </div>
                                    }

                                    {/*  Import Stats  */}
                                    {isImported &&
                                        <div className={'row'}>
                                            <div className="col-md-4 col-6 mt-3">
                                                <p className={'text-muted p-0 m-0'}>
                                                    Records Imported
                                                </p>
                                                <h5>
                                                    {importResponse.success}
                                                </h5>
                                            </div>
                                            <div className="col-md-4 col-6 mt-3">
                                                <p className={'text-muted p-0 m-0'}>
                                                    Records Failed
                                                </p>
                                                <h5>
                                                    {parseInt(importResponse.failed) ? importResponse.failed : 0}
                                                </h5>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>


                            {/* Header Mapper */}
                            {/*<div>*/}
                            {/*    {isMapperUIVisible &&*/}
                            {/*        <div className="row mt-5">*/}
                            {/*            <h3>*/}
                            {/*                {isLoading &&*/}
                            {/*                    <Skeleton variant="text" sx={{fontSize: '2rem'}}/>*/}
                            {/*                }*/}
                            {/*                {!isLoading &&*/}
                            {/*                    'Column Mapper'*/}
                            {/*                }*/}
                            {/*            </h3>*/}
                            {/*            {headerRow.map(function (item, i) {*/}
                            {/*                return <div className={'row my-3'} key={i}>*/}
                            {/*                    <div className="col-4">*/}
                            {/*                        <p className={'pt-3'}>*/}
                            {/*                            {item}*/}
                            {/*                        </p>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="col-4">*/}
                            {/*                        <FormControl fullWidth>*/}
                            {/*                            <InputLabel id="demo-simple-select-label">System Field</InputLabel>*/}
                            {/*                            <Select*/}
                            {/*                                labelId="demo-simple-select-label"*/}
                            {/*                                id="demo-simple-select"*/}
                            {/*                                value={mapper[item].value}*/}
                            {/*                                label="System Field"*/}
                            {/*                                onChange={(event: SelectChangeEvent) => {*/}
                            {/*                                    let currentMapping = mapper;*/}
                            {/*                                    currentMapping[item].value = event.target.value;*/}
                            {/*                                    setMapper(currentMapping);*/}
                            {/*                                    forceUpdate();*/}
                            {/*                                }}*/}
                            {/*                            >*/}
                            {/*                                {allBookFieldsData.map(function (data, idx) {*/}
                            {/*                                    return <MenuItem key={idx}*/}
                            {/*                                                     value={data.title}>{data.displayName}</MenuItem>;*/}
                            {/*                                })}*/}
                            {/*                            </Select>*/}
                            {/*                        </FormControl>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="col-4">*/}
                            {/*                        <TextField id="outlined-basic"*/}
                            {/*                                   className={'full-width'}*/}
                            {/*                                   label="Default Value"*/}
                            {/*                                   variant="outlined"*/}
                            {/*                                   value={mapper[item]['default']}*/}
                            {/*                                   onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
                            {/*                                       let currentMapping = mapper;*/}
                            {/*                                       currentMapping[item].default = event.target.value;*/}
                            {/*                                       setMapper(currentMapping);*/}
                            {/*                                       forceUpdate();*/}
                            {/*                                   }}*/}
                            {/*                        />*/}
                            {/*                    </div>*/}
                            {/*                </div>;*/}
                            {/*            })}*/}
                            {/*        </div>*/}
                            {/*    }*/}
                            {/*</div>*/}
                        </div>
                    }

                    {fileNames.length == 0 &&
                        <div className={'row'}>

                            {/* Template Selector */}
                            <div className="col-md-8">
                                {props.importType == 'pretermination' &&
                                    <h4 className={'alert alert-warning'}>
                                        Pre Termination Template Selected
                                    </h4>
                                }

                                {props.importType == 'collection' &&
                                    <div className={'row'}>

                                        <div className="col-md-6">
                                            <h5 className={'alert alert-warning'}>
                                                Collection Book Template Selected
                                            </h5>
                                        </div>

                                        <div className="col-md-6">
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="bookTemplateIdLabel">
                                                    Select Campaign
                                                </InputLabel>
                                                {allCampaigns.length > 0 &&
                                                    <Select labelId="campaignIdLabel"
                                                            id="campaignIdSelect"
                                                            onChange={(event: any) => {
                                                                // get campaign obj
                                                                const selectedItem = allCampaigns.filter(_campaign => _campaign.id == event.target.value)[0];
                                                                setSelectedCampaign(selectedItem);
                                                            }}
                                                            value={selectedCampaign?.id}
                                                            label="Select Campaign">
                                                        {
                                                            allCampaigns.map((el: any) =>
                                                                <MenuItem value={el.id}
                                                                          key={el.id}> {el.name}
                                                                </MenuItem>)
                                                        }
                                                    </Select>
                                                }
                                                {allCampaigns.length == 0 &&
                                                    <>
                                                        <Select
                                                            label="Select Campaign"
                                                            disabled>
                                                            <MenuItem value={''}>
                                                                No Data
                                                            </MenuItem>
                                                        </Select>
                                                        <FormHelperText>
                                                            No Data
                                                        </FormHelperText>
                                                    </>
                                                }
                                            </FormControl>
                                        </div>


                                    </div>
                                }

                                {/*<FormControl fullWidth={true}>*/}
                                {/*    <InputLabel id="bookTemplateIdLabel">*/}
                                {/*        Select Template*/}
                                {/*    </InputLabel>*/}
                                {/*    {allBookTemplates.length > 0 &&*/}
                                {/*        <Select labelId="bookTemplateIdLabel"*/}
                                {/*                id="bookIdTemplateSelect"*/}
                                {/*                onChange={(event: any) => {*/}
                                {/*                    // get template obj*/}
                                {/*                    const selectedTemplate = allBookTemplates.filter(_template => _template.id == event.target.value)[0];*/}
                                {/*                    setBookTemplate(selectedTemplate);*/}
                                {/*                }}*/}
                                {/*                value={bookTemplate.id}*/}
                                {/*                label="Select Template">*/}
                                {/*            {*/}
                                {/*                allBookTemplates.map((el: any) =>*/}
                                {/*                    <MenuItem value={el.id}*/}
                                {/*                              key={el.id}> {el.name}*/}
                                {/*                    </MenuItem>)*/}
                                {/*            }*/}
                                {/*        </Select>*/}
                                {/*    }*/}
                                {/*    {allBookTemplates.length == 0 &&*/}
                                {/*        <>*/}
                                {/*            <Select*/}
                                {/*                disabled>*/}
                                {/*                <MenuItem value={''}>*/}
                                {/*                    No Data*/}
                                {/*                </MenuItem>*/}
                                {/*            </Select>*/}
                                {/*            <FormHelperText>*/}
                                {/*                No Data*/}
                                {/*            </FormHelperText>*/}
                                {/*        </>*/}
                                {/*    }*/}
                                {/*</FormControl>*/}
                            </div>

                            {/* File Selector */}
                            <div className="col-md-4">
                                {Object.keys(bookTemplate).length > 0 && (props.importType == 'collection' && Object.keys(selectedCampaign).length > 0 || props.importType == 'pretermination') &&
                                    <Button onClick={() => inputRef.current.click()}
                                            fullWidth
                                            className={'py-3'}
                                            variant="outlined">
                                        select account file
                                        to
                                        upload</Button>
                                }

                                {/* Hide the crappy looking default HTML input */}
                                <input
                                    ref={inputRef}
                                    type="file"
                                    accept=".csv"
                                    style={{display: 'none'}}
                                    onChange={(e) => {
                                        onFileUpload(e.target.files[0], e)
                                        inputRef.current.value = null;
                                    }}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>

            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isUploading || isProcessing}
            >
                <div className={'d-block text-center'}>
                    <CircularProgress color="inherit"/>

                    {isUploading &&
                        <h5>
                            Uploading Book
                        </h5>
                    }

                    {isProcessing &&
                        <h5>
                            Processing Book
                        </h5>
                    }

                </div>
            </Backdrop>

            {/* Alerts */}
            <AlertM ref={notifications}/>

        </div>
    );
});

export default AccountFileUploadM;
