// @ts-nocheck

import React, {useEffect} from 'react';
import {useStyles} from './Styles';
import Box from '@mui/material/Box';
import Tooltip from "@mui/material/Tooltip";
import {Button, Card, CardContent, IconButton} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core';
import {useTranslation} from "react-i18next";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import CardActions from "@mui/material/CardActions";
import DataDisplayM from "../../../../Helpers/DataDisplayM/DataDisplayM";
import {log} from "../../../../../Services/LoggerService";
import {decrypt} from "../../../../../Services/CryptoService";

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function UserView({data = null}) {
    // css for module
    const classes = useStyles();

    // translation
    const {t} = useTranslation();

    // ui handlers
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    // handle cancel event
    const handleCancelEvent = () => {
        handleClose();
    }

    // format team names
    let teamNames = '';
    let teamCount = data.userUserTeam.length;
    if(teamCount>0){
        data.userUserTeam.forEach((_team, _i) => {
            teamNames += _team.userTeamTeam.name
            if(_i != teamCount-1){
                teamNames += ', '
            }
        })
    } else {
        teamNames = 'No team assigned';
    }


    // hooks
    useEffect(() => {

    }, []);


    return (
        <div>
            <Box sx={{display: 'flex'}}>
                <Tooltip title={t('common.view')}>
                    <IconButton aria-label="delete" size="small" onClick={handleOpen}>
                        <FontAwesomeIcon className="edit-btn" icon='eye'/>
                    </IconButton>
                </Tooltip>
            </Box>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        <Card sx={{minWidth: '75vw'}}>
                            <CardContent>
                                <div className="py-4 px-md-4 px-0">
                                    <h1 className="mb-3">{t('common.recordDetails')}</h1>
                                    <div className="row full-width">


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('user.firstName')} data={data.firstName}/>
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('user.middleName')} data={data.middleName}/>
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('user.lastName')} data={data.lastName}/>
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('user.email')} data={decrypt(data.email)}/>
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('user.contact')} data={data.contact}/>
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('user.roleId')} data={data?.role?.title}/>
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('user.teamId')} data={teamNames}/>
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('user.isActive')} data={data.isActive}/>
                                            }                                        </div>


                                        {/*<div className="col-md-6">*/}
                                        {/*    {data &&*/}
                                        {/*        <DataDisplayM label={t('user.isMicrosoftLinked')}*/}
                                        {/*                      data={data.isMicrosoftLinked}/>*/}
                                        {/*    }                                        </div>*/}


                                        {/*<div className="col-md-6">*/}
                                        {/*    {data &&*/}
                                        {/*        <DataDisplayM label={t('user.isGoogleLinked')}*/}
                                        {/*                      data={data.isGoogleLinked}/>*/}
                                        {/*    }                                        </div>*/}


                                        {/*<div className="col-md-6">*/}
                                        {/*    {data &&*/}
                                        {/*        <DataDisplayM label={t('user.password')} data={data.password}/>*/}
                                        {/*    }                                        </div>*/}


                                        {/*<div className="col-md-6">*/}
                                        {/*    {data &&*/}
                                        {/*        <DataDisplayM label={t('user.token')} data={data.token}/>*/}
                                        {/*    }                                        </div>*/}


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('user.lastLogin')} data={data.lastLogin}/>
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('user.locationId')}
                                                              data={data?.userLocation?.location}/>
                                            }                                        </div>


                                        {/*<div className="col-md-6">*/}
                                        {/*    {data &&*/}
                                        {/*        <DataDisplayM label={t('user.teamId')} data={data.teamId}/>*/}
                                        {/*    }                                        </div>*/}


                                        {/*<div className="col-md-6">*/}
                                        {/*    {data &&*/}
                                        {/*        <DataDisplayM label={t('user.reportingTeamId')}*/}
                                        {/*                      data={data.reportingTeamId}/>*/}
                                        {/*    }                                        </div>*/}


                                        {/*<div className="col-md-6">*/}
                                        {/*    {data &&*/}
                                        {/*        <DataDisplayM label={t('user.legalAgent')} data={data.legalAgent}/>*/}
                                        {/*    }                                        </div>*/}


                                        {/*<div className="col-md-6">*/}
                                        {/*    {data &&*/}
                                        {/*        <DataDisplayM label={t('user.idNumber')} data={data.idNumber}/>*/}
                                        {/*    }                                        </div>*/}


                                        {/*<div className="col-md-6">*/}
                                        {/*    {data &&*/}
                                        {/*        <DataDisplayM label={t('user.collectorId')} data={data.collectorId}/>*/}
                                        {/*    }                                        </div>*/}


                                        {/*<div className="col-md-6">*/}
                                        {/*    {data &&*/}
                                        {/*        <DataDisplayM label={t('user.phoneUsername')}*/}
                                        {/*                      data={data.phoneUsername}/>*/}
                                        {/*    }                                        </div>*/}


                                        {/*<div className="col-md-6">*/}
                                        {/*    {data &&*/}
                                        {/*        <DataDisplayM label={t('user.phonePassword')}*/}
                                        {/*                      data={data.phonePassword}/>*/}
                                        {/*    }                                        </div>*/}


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('user.username')} data={data.username}/>
                                            }                                        </div>


                                    </div>
                                </div>
                            </CardContent>
                            <CardActions>
                                <div className="pb-4 pt-2 px-md-4 px-2 " style={{width: '100%', textAlign: 'right'}}>

                                    {/* Cancel Button */}
                                    <Button variant="outlined"
                                            onClick={handleCancelEvent}>
                                        {t('common.cancel')}
                                    </Button>

                                    &nbsp;&nbsp;
                                </div>
                            </CardActions>
                        </Card>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default UserView;
