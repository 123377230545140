// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import { useStyles } from './Styles';
import '../../../../../index.scss';
import { getAll, post } from "../../../../../Services/GenericApiService";
import { formatData } from "../../../../../Services/DataFormatService";
import { useTranslation } from "react-i18next";
import AlertM from '../../../../Helpers/AlertM/AlertM';
import { log } from "../../../../../Services/LoggerService";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useForm } from "react-hook-form";
import MUIDataTable from "mui-datatables";
import dayjs, { Dayjs } from 'dayjs';
import { Skeleton, Button } from "@mui/material";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import { FormControl, FormHelperText } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import { RootStateOrAny, useSelector } from "react-redux";
import TextDisplayM from '../../../../Helpers/TextDisplayM/TextDisplayM';
import ApexPieChart from '../../Chart/Apex/ApexPieChart/ApexPieChart';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function PtpReport() {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = 'debtordetail';
    const moduleUser = 'user'
    const moduleExportLeads = '/export/ptpReport';
    const moduleTeam = 'team';

    // data vars
    const [allData, setAllData] = React.useState([]);

    const [allAgent, setAllAgent] = React.useState({});
    const [allTeam, setAllTeam] = React.useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [paginationFlag, setPaginationFlag] = useState(false);
    const [selectedAgentIds, setSelectedAgentIds] = useState([]);
    const [selectedTeamIds, setSelectedTeamIds] = useState([]);
    const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs(new Date()));
    const [endDate, setEndDate] = React.useState<Dayjs | null>(dayjs(new Date()));
    const [arrangeStartDate, setArrangeStartDate] = React.useState<Dayjs | null>(dayjs(new Date()));
    const [arrangeEndDate, setArrangeEndDate] = React.useState<Dayjs | null>(dayjs(new Date()));

    let columns = [
        { name: "ID", options: { filterOptions: { fullWidth: true } } },
        { name: "Debtor", options: { filterOptions: { fullWidth: true } } },
        { name: "Debtor ID No", options: { filterOptions: { fullWidth: true } } },
        { name: "Debtor Account No.", options: { filterOptions: { fullWidth: true } } },
        { name: "Agent", options: { filterOptions: { fullWidth: true } } },
        { name: "PTP Arranged Date", options: { filterOptions: { fullWidth: true } } },
        { name: "PTP Due Date", options: { filterOptions: { fullWidth: true } } },
        { name: "PTP Amount", options: { filterOptions: { fullWidth: true } } },
        { name: "PTP Method", options: { filterOptions: { fullWidth: true } } },
        { name: "Status", options: { filterOptions: { fullWidth: true } } },
        { name: "Recurring PTP", options: { filterOptions: { fullWidth: true } } },
    ];

    const [tableData, setTableData] = React.useState([]);
    // ui controls
    const [loading, setLoading] = React.useState(true);

    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // table ui controls
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");

    const handleAgentChange = (event) => {
        setSelectedAgentIds(event.target.value);
    }

    const handleTeamChange = (event) => {
        fetchAgent(event.target.value);
        setSelectedTeamIds(event.target.value);
    }

    const formatDateOnly = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    // fetch dependent data
    const fetch = () => {
        fetchTeam();
    }

    //table data

    const getCollectionAccount = () => {
        setLoading(true); // show loader
        let payload = {
            "startDate": startDate,
            "endDate": endDate,
            "arrangeStartDate": arrangeStartDate,
            "arrangeEndDate": arrangeEndDate,
            "agentId": selectedAgentIds,
            "teamId": selectedTeamIds,
        }
        post(moduleMain + '/collection/ptpReport', payload, currentPage, pageSize)
            .then((_res: any) => {
                setAllData(_res)
                setTotalRowCount(_res.count);
                setAllData(_res.rows);
                setPaginationFlag(true);

                const rows: any = [];
                _res.rows.forEach((_record: any) => {
                    rows.push([_record.id, _record['ptpDebtorDetails.firstName'] + ' ' + _record['ptpDebtorDetails.surName'], _record.debtorId, _record['ptpDebtorDetails.billAccNo'], _record['ptpDebtorDetails.debtordetailDebtoragent.debtorAgentUser.email'], formatDateOnly(_record['ptpMethod.createdAt']), formatDateOnly(_record.ptpDate), _record.amount, _record['ptpMethod.name'], 'To be discussed', _record.isRecurring === 0 ? 'No' : 'Yes'])
                });

                setTableData(rows);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Agents
    const fetchAgent = (teamIds) => {
        setAllAgent([]);
        setLoading(true); // show loader
        let payload = {
            teamId: teamIds
        }
        post(moduleUser + '/agent/all', payload)
            .then((_res: any) => {
                setAllAgent(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Team
    const fetchTeam = () => {
        setLoading(true); // show loader
        getAll(moduleTeam)
            .then((_res: any) => {
                setAllTeam(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const handleExportData = () => {
        setLoading(true);   // Set Loading
        let payload = {
            "startDate": startDate,
            "endDate": endDate,
            "arrangeStartDate": arrangeStartDate,
            "arrangeEndDate": arrangeEndDate,
            "agentId": selectedAgentIds,
            "teamId": selectedTeamIds,
        }

        // post filters
        post(moduleMain + moduleExportLeads, payload)
            .then((_res: any) => {
                window.open(_res.url, '_blank');
                // @ts-ignore
                // notifications.current.successAlert('Records Rejected', 'Records Were Rejected');
                setLoading(false);
            })
            .catch((_err: any) => {
                log(_err);
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                setLoading(false);   // loading is set false in fetch
            })
    }

    // form methods
    const onSubmit = (_data: any) => {

    }

    useEffect(() => {
        fetch()
    }, [currentPage])

    useEffect(() => {
        if (paginationFlag) {
            getCollectionAccount();
        }
    }, [currentPage])


    let options = {
        search: true,
        download: true,
        print: true,
        viewColumns: true,
        filter: true,
        filterType: "textField",
        responsive: "simple",
        resizableColumns: false,
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 50, 75, 100],
        onChangePage: (currentPage) => {
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            setPageSize(numberOfRows);
        },
        onDownload: () => {
            if (!loading) {
                handleExportData();
            }
            // return false to avoid datatable download
            return false;
        },
        page: currentPage,
        count: totalRowCount,
        serverSide: true,
        loading: true,
        tableBodyHeight: tableBodyHeight,
        selectableRowsHideCheckboxes: true,
        sort: true,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        },
        onTableChange: (action: any, state: any) => {
        },
    };

    if (tableData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ?
                        <Box>
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                        </Box> :
                        'Sorry, there is no matching data to display',
                },
            },
        };
    }


    return (
        <>
            <Box component="main">
                {!loading &&
                    <>
                        {/* Filter row */}
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={(theme == 'dark' ? 'bg-dark' : '') + "card shadow-lg ms-0 me-0"}>
                                <div className="card-body">
                                    <div className='row'>

                                        {/* Assigned Start Date */}
                                        <div className="col-4 mt-3">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="Arrange Date From"
                                                    className='full-width'
                                                    value={arrangeStartDate}
                                                    onChange={(newValue) => setArrangeStartDate(newValue)}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                        {/* Assigned End Date */}
                                        <div className="col-4 mt-3">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="Arrange Date To"
                                                    className='full-width'
                                                    value={arrangeEndDate}
                                                    onChange={(newValue) => setArrangeEndDate(newValue)}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                        {/* Apply Filter Button */}
                                        <div className='col-4 mt-3'>
                                            <Button
                                                onClick={getCollectionAccount}
                                                className={'full-width bg-custom-gradient py-3 px-5'}
                                                variant="contained">
                                                Apply Filters
                                            </Button>
                                        </div>

                                        {/* Start Date */}
                                        <div className="col-4 mt-3">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="PTP Due Date From"
                                                    className='full-width'
                                                    value={startDate}
                                                    onChange={(newValue) => setStartDate(newValue)}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                        {/* End Date */}
                                        <div className="col-4 mt-3">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="PTP Due Date To"
                                                    className='full-width'
                                                    value={endDate}
                                                    onChange={(newValue) => setEndDate(newValue)}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                        <div className='col-4 mt-3'>
                                            {/* Field Team */}
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="teamIdLabel">
                                                    Team
                                                </InputLabel>
                                                {allTeam.length > 0 &&
                                                    <Select labelId="teamIdLabel"
                                                        id="teamIdSelect"
                                                        {...register("teamId", { required: false })}
                                                        error={!!errors.id}
                                                        value={selectedTeamIds}
                                                        onChange={handleTeamChange} // Handle selection disposition changes
                                                        multiple
                                                        label="teamId">
                                                        {
                                                            allTeam.map((el: any) =>
                                                                <MenuItem value={el.id}
                                                                    key={el.id}> {el.name}
                                                                </MenuItem>)
                                                        }
                                                    </Select>
                                                }
                                                {allTeam.length == 0 &&
                                                    <>
                                                        <Select labelId="teamIdLabel"
                                                            disabled
                                                            id="teamIdSelect"
                                                            {...register("teamId", { required: false })}
                                                            error={!!errors.id}
                                                            label="agentId">
                                                            <MenuItem value={''}>
                                                                No Data
                                                            </MenuItem>
                                                        </Select>
                                                        <FormHelperText>
                                                            No Data
                                                        </FormHelperText>
                                                    </>
                                                }
                                            </FormControl>
                                        </div>


                                        <div className='col-12 mt-3'>
                                            {/* Field Agent */}
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="agentIdLabel">
                                                    Agent
                                                </InputLabel>
                                                {allAgent.length > 0 &&
                                                    <Select labelId="agentIdLabel"
                                                        id="agentIdSelect"
                                                        {...register("id", { required: false })}
                                                        error={!!errors.id}
                                                        value={selectedAgentIds}
                                                        onChange={handleAgentChange} // Handle selection disposition changes
                                                        multiple
                                                        label="agentId">
                                                        {
                                                            allAgent.map((el: any) =>
                                                                <MenuItem value={el['userTeamUser.id']}
                                                                    key={el['userTeamUser.id']}> {el['userTeamUser.email']}
                                                                </MenuItem>)
                                                        }
                                                    </Select>
                                                }
                                                {(Object.keys(allAgent).length === 0 || allAgent.length == 0) &&
                                                    <>
                                                        <Select labelId="agentIdLabel"
                                                            disabled
                                                            id="agentIdSelect"
                                                            {...register("agentId", { required: false })}
                                                            error={!!errors.id}
                                                            value={watch('id')}
                                                            label="agentId">
                                                            <MenuItem value={''}>
                                                                No Data
                                                            </MenuItem>
                                                        </Select>
                                                        <FormHelperText>
                                                            No Data
                                                        </FormHelperText>
                                                    </>
                                                }
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </>
                }
                {loading &&
                    <Skeleton height={100} />
                }

                {/* Data Table */}
                {!loading &&
                    <div className="mt-5">
                        <Paper elevation={6}>
                            <MUIDataTable
                                title={t('common.allRecords')}
                                data={tableData}
                                columns={columns}
                                // @ts-ignore
                                options={options}
                            />
                        </Paper>
                    </div>
                }


                {loading &&

                    <Skeleton height={250} />
                }

                {/* Alerts */}
                <AlertM ref={notifications} />

            </Box>
        </>
    );
}

export default PtpReport;
